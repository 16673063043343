import { loadGoogleAds } from './googleAds';
import { loadGoogleAds as googleAdsLazyload } from './googleAdsLazyload';
import { loadRelevant } from './relevant';
import tv2Ads from './tv2ads/ad-config';
import { getTracking } from './tv2ads/tracking';
import { getAdvertisementId } from './utils/advertisementId';

// @TODO: when refresh can be handled by relevant, we should disable
// our proprietary solution
const useProprietaryRefresh = true;

(async () => {
  if (tv2Ads.adsDisabled) {
    const log = window.tv2.utils.setupLog('Ads (disabled) 💸');
    log.info('Ads disabled by configuration');
    return;
  }

  const advertisementId = await getAdvertisementId();
  const tracking = getTracking();

  const queryParams = new URLSearchParams(window.location.search);

  // temp check for lazyload query param
  // to verify solution in production
  if (queryParams.has('lazyload')) {
    loadRelevant(tracking, advertisementId);
    window.tv2messages.onceWithTimeout('hasConsent', 1e10, (consent) => {
      googleAdsLazyload(
        consent,
        tracking,
        advertisementId,
        useProprietaryRefresh,
      );
    });

    return;
  }

  // temp check for segments query param
  // to verify solution in production
  if (queryParams.has('segments')) {
    // We need to wait for traceIds to be available before we can fetch segments.
    window.tv2messages.onceWithTimeout('traceIds', 150, async (data) => {
      const segments = data && data['audience-supplier-segments'];

      // rYield loads before consent, and awaits consent internally
      // when it comes to header bidding, speed is of the essence.
      //  We can prepare a couple of things while consent is retrieved
      loadRelevant(tracking, advertisementId, segments);

      window.tv2messages.onceWithTimeout('hasConsent', 1e10, (consent) => {
        loadGoogleAds(
          consent,
          tracking,
          advertisementId,
          useProprietaryRefresh,
          segments,
        );
      });
    });

    return;
  }

  /**
   * This is the old solution, which is still in use.
   * We will remove this when the new solution is verified
   */

  // rYield loads before consent, and awaits consent internally
  // when it comes to header bidding, speed is of the essence.
  // We can prepare a couple of things while consent is retrieved
  loadRelevant(tracking, advertisementId);

  window.tv2messages.onceWithTimeout('hasConsent', 1e10, (consent) => {
    loadGoogleAds(consent, tracking, advertisementId, useProprietaryRefresh);
  });
})();
